import { message } from "antd";
import { useEffect, useState } from "react";

import { api } from "../../api";
import { ITask } from "../../utils/common-interfaces";
import { ReactComponent as WelcomeIcon } from "../../assets/images/popper-icon.svg";
import { ReactComponent as BuildProfileIcon } from "../../assets/images/pencil-emoji-icon.svg";
import { ReactComponent as SlackIcon } from "../../assets/images/slack-icon.svg";
import { ReactComponent as ManagerIcon } from "../../assets/images/hand-shake-icon.svg";
import { ReactComponent as IntroIcon } from "../../assets/images/hand-wave-icon.svg";
import { getApiErrorMsg, getApiResponseMsg } from "../../utils/object-util";
import OnboardingLayout from "../../components/layouts/onboarding-layout";
import Spinner from "../../components/spinner";
import { useCurrentUser} from "../../hooks";
import PrivateHeader from "../../components/private-header";
import CustomButton from "../../components/button";
import { authService } from "../../auth";

import LeftSteps from "./left-steps";
import Tasks from "./tasks";
import BuildProfile from "./tasks/build-profile";

export interface IOnboardingTask {
	icon?: React.ReactNode;
	rightContent?: {
		type?: "self_check" | "custom" | "integration";
		greetingIcon?: React.ReactNode;
		title: string;
		description: string | React.ReactNode;
		btnText?: string;
		onBtnClick?: (values: any) => void;
		successCallBack?: () => void;
		btnIcon?: string | React.ReactNode;
		profilePic?: {
			pictureUrl: string;
			pictureTitle: string;
			pictureAlt: string;
		};
		component?: React.ReactNode;
	};
}

const UserOnboarding = () => {
	const { user } = useCurrentUser();
	const [currentTask, setCurrentTask] = useState("");
	const [taskLabels, setTaskLabels] = useState<
		{
			labels: string;
			completed: boolean;
		}[]
	>([]);

	const [form, setForm] = useState<{
		id: string;
		tasks: ITask[];
	}>();
	const [isLoading, setIsLoading] = useState(user?.team_member.status === "onboarding");

	useEffect(() => {
		if (user?.team_member.status === "onboarding") getUserOnboardingForm();
	}, []);

	useEffect(() => {
		if (form)
			setTaskLabels(
				form?.tasks.map((task) => ({
					labels: task.label,
					completed: task.tasks_responses.length > 0,
				}))
			);
	}, [form]);
	
	 if (user?.team_member.status === "PendingActivation")
		return (
			<>
				<PrivateHeader />
				<div
					style={{
						height: "100vh",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						flexDirection: "column",
						gap: "1rem",
					}}
				>
					<div>Your activation is pending on our side.</div>
					<div>Please Contact your admin for further details.</div>
					<CustomButton
						type="primary"
						onClick={() => {
							authService.logout();
						}}
					>
						Log Out
					</CustomButton>
				</div>
			</>
		);

	const getFormTaskDetails = (label: string) => {
		const selectedTask = form?.tasks?.find((task) => task.label === label);
		return selectedTask;
	};

	const moveToPendingActivation = () => {
		setIsLoading(true);
		api
			.put({
				path: `/team-members/${user?.team_member_id}`,
				service: "job",
				formdata: {
					status: "PendingActivation",
				},
			})
			.then(() => {
				message.success({
					content: "Details confirmed successfully",
					key: "team-member-exit",
					duration: 3,
				});

				window.location.reload();
			})
			.catch((error) => {
				message.error({
					content: getApiErrorMsg(error),
					key: "team-member-exit",
					duration: 3,
				});
			})
			.finally(() => setIsLoading(false));
	}

	const onSubmit = (values: any, successCallback?: () => void) => {
		api
			.put({
				path: `/forms/${form?.id}/responses`,
				service: "job",
				formdata: {
					team_member_id: user?.team_member_id,
					response: [
					  {
							task_id: values?.id,
							...(values?.is_skipped ?{is_skipped: true} : {response:  "yes"}),
						},
					],
				},
			})
			.then((res) => {
				const data = res.data.data.response[0];

				setForm((prev) => {
					if (prev) {
						const tempTask = [...(prev.tasks ?? [])];
						const index = tempTask.findIndex((el: ITask) => el.id === values.id);
						if (index>-1) tempTask[index].tasks_responses = [data];
						return { ...prev, tasks: tempTask };
					}

					return prev;
				});

				const lastIndex = taskLabels.findIndex((el) => el.labels === currentTask) === taskLabels.length-1;
				if(lastIndex) moveToPendingActivation();

				setCurrentTask((prev) => {
					const taskIndex = taskLabels.findIndex((el) => el.labels === prev);
					if (taskIndex === -1 || taskIndex === taskLabels.length-1) return prev;
					if (taskIndex < taskLabels.length-1) return taskLabels[taskIndex + 1].labels;
					return taskLabels[0].labels;
				});

				message.success({
					content: getApiResponseMsg(res),
					key: "exit-settings-docs-success",
					duration: 3,
				});
			})
			.catch((err) => {
				message.error({
					content: getApiErrorMsg(err),
					key: "exit-settings-error",
					duration: 3,
				});
			})
			.finally(() => successCallback?.());
	};

	const onboardingTasks: Record<
		string,
		{
			rightContent: any;
		}
	> = {
		welcome: {
			rightContent: {
				greetingIcon: <WelcomeIcon />,
				type: getFormTaskDetails("welcome")?.type,
				title: getFormTaskDetails("welcome")?.title,
				description: getFormTaskDetails("welcome")?.description,
				btnText: "Start your journey",
				onBtnClick: onSubmit,
			},
		},
		about: {
			rightContent: {
				type: getFormTaskDetails("about")?.type,
				greetingIcon: <IntroIcon />,
				title: getFormTaskDetails("about")?.title,
				description: getFormTaskDetails("about")?.description,
				btnText: "Meet your manager",
				onBtnClick: onSubmit,
			},
		},
		manager: {
			rightContent: {
				type: getFormTaskDetails("manager")?.type,
				greetingIcon: <ManagerIcon />,
				title: getFormTaskDetails("manager")?.title,
				description: getFormTaskDetails("manager")?.description,
				btnText: "Let’s build your profile",
				onBtnClick: onSubmit,
			},
		},
		profile: {
			rightContent: {
				type: getFormTaskDetails("profile")?.type,
				greetingIcon: <BuildProfileIcon />,
				component: (
					<BuildProfile
						task={getFormTaskDetails("profile")}
						onSubmit={onSubmit}
						formID={form?.id}
					/>
				),
				title: getFormTaskDetails("profile")?.title,
				description: getFormTaskDetails("profile")?.description,
			},
		},
		Slack: {
			rightContent: {
				type: getFormTaskDetails("Slack")?.type,
				greetingIcon: <SlackIcon />,
				title: getFormTaskDetails("Slack")?.title,
				description: getFormTaskDetails("Slack")?.description,
				btnText: "Join Slack",
				onBtnClick: onSubmit,
			},
		},
	};

	const getUserOnboardingForm = () => {
		api
			.get({
				path: "/forms/form/team-member/new_onboarding_process",
				service: "job",
			})
			.then((res) => {
				const data = res.data.data;
				if (data) {
					setForm(data);
				}
			})
			.catch((err) => {
				console.log(err);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const handleStepChange = (label: string) => {
		const selectedTask = form?.tasks?.find((task) => task.label === label);
		if (selectedTask) setCurrentTask(selectedTask.label);
	};

	return isLoading ? (
		<Spinner />
	) : form?.tasks.length ? (
		<OnboardingLayout
			leftSideContent={
				<LeftSteps
					tasks={form?.tasks ?? []}
					currentStep={currentTask}
					setCurrentStep={setCurrentTask}
					handleStepChange={handleStepChange}
					onboardingTasks={onboardingTasks}
				/>
			}
			rightSideContent={
				<Tasks
					selectedTaskKey={currentTask}
					onboardingTasks={onboardingTasks}
					task={getFormTaskDetails(currentTask)}
					formID={form?.id ?? ""}
					skipTask={onSubmit}
				/>
			}
		/>
	) : (
		<></>
	);
};

export default UserOnboarding;
