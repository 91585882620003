import dayjs from "dayjs";
import { marked } from "marked";
import React, { useEffect, useState } from "react";

import { api } from "../../../../api";
import AvatarIcon from "../../../../components/avatar-icon";
import CustomButton from "../../../../components/button";
import InfoItem from "../../../../components/info-item";
import { genderMap } from "../../../../constants";
import { useCurrentUser } from "../../../../hooks";
import { ITask } from "../../../../utils/common-interfaces";

import styles from "./passTasks.module.scss";

interface IProps {
	greetingIcon?: string | React.ReactNode;
	title?: string;
	profilePic?: {
		pictureUrl: string;
		pictureTitle: string;
		pictureAlt: string;
	};
	description?: string;
	btnText?: string;
	btnIcon?: string | React.ReactNode;
	completed?: boolean;
	onBtnClick?: (values: any, successCallback?: () => void) => void;
	component?: React.ReactNode;
	task?: ITask;
}

export interface ITeamMemberIntegration {
	id: string;
	action_performed: boolean;
	is_integrated: boolean;
	data: null;
	integration_id: string;
	team_member_id: string;
	integration: Integration;
}

export interface Integration {
	id: string;
	tenant_id: string;
	type: string;
	name: string;
	workspace_url: string;
	description: string;
	is_active: boolean;
	created_at: Date;
	updated_at: Date;
}

const PassTasks = (props: IProps) => {
	const {
		title,
		greetingIcon,
		profilePic,
		description,
		btnText,
		btnIcon,
		completed,
		onBtnClick,
		component,
		task,
	} = props;

	const { user } = useCurrentUser();

	const content =
		description
			?.replace("[Company Name]", `**${user?.tenant.organization_name}**`)
			.replace("[Position]", `**${user?.team_member?.position.name}**`)
			.replace("[Joining Date]", dayjs(user?.team_member.date_of_joining).format(`**DD-MM-YYYY**`))
			.replace("[Manager Name]", `**${user?.team_member.reporting_to.full_name}**`)
			.replace("[He/She/They]", genderMap[user?.team_member.gender?.toLowerCase() ?? "male"].pronoun) ?? "";

	const markedContent = marked.parse(content);
	const [integration, setIntegration] = useState<ITeamMemberIntegration>();
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (task?.type === "integration" && task?.unique_identifier) {
			setIsLoading(true);
			api
				.get({
					path: `/team-member-integrations/${task?.unique_identifier}`,
					service: "job",
				})
				.then((res) => {
					setIntegration(res.data.data);
				})
				.catch((err) => {
					console.log(err);
				})
				.finally(() => setIsLoading(false));
		}
	}, [task]);

	return (
		<div className={styles.wrapper}>
			{greetingIcon &&
				(typeof greetingIcon === "string" ? (
					<img src={greetingIcon} alt="greeting-icon" className={styles.greetingIcon} />
				) : (
					greetingIcon
				))}
			{title && <h2 className={styles.title}>{title}</h2>}
			{profilePic && (
				<div className={styles["profile-pic"]}>
					<img
						src={profilePic.pictureUrl}
						alt={profilePic.pictureAlt}
						className={styles.profilePic}
					/>
					<InfoItem heading={profilePic.pictureTitle} value={profilePic.pictureAlt} />
				</div>
			)}
			{task?.label === "manager" && (
				<div className={styles["reporting-manager-wrapper"]}>
					<AvatarIcon
						wrapperStyle={{ width: 58, height: 58 }}
						profilePic={user?.team_member?.reporting_to?.profile_pic}
						firstName={user?.team_member?.reporting_to?.first_name as string}
						lastName={user?.team_member?.reporting_to?.last_name as string}
						color={user?.team_member?.reporting_to?.rgb_icon as string}
						contentClassName={styles["reporting-manager"]}
						contentStyle={{ width: "100%", height: "100%", fontSize: 20 }}
					/>
					<div className={styles["reporting-manager-info"]}>
						<div className={styles["reporting-manager-name"]}>
							{user?.team_member?.reporting_to?.full_name}
						</div>
						<div className={styles["reporting-manager-position"]}>
							{user?.team_member?.reporting_to?.position?.name ?? "--"}
						</div>
					</div>
				</div>
			)}
			{description && (
				<div
					style={{ lineHeight: "1.5" }}
					dangerouslySetInnerHTML={{ __html: markedContent as string }}
				/>
			)}
			{btnText && onBtnClick && task && (
				<CustomButton
					style={{ width: "max-content" }}
					disabled={completed}
					size="small"
					type="primary"
					htmlType="submit"
					onClick={() => {
						if (task?.type === "integration" && !integration?.action_performed) {
							setIsLoading(true);
							api
								.put({
									path: `/team-member-integrations/${task?.unique_identifier}`,
									service: "job",
									formdata: {
										action_performed: true,
									},
								})
								.then((res) => {
									setIntegration(
										(data) => ({ ...data, action_performed: true }) as ITeamMemberIntegration
									);
									window.open(
										integration?.integration.workspace_url ?? "https://www.google.com/",
										"_blank"
									);
								})
								.catch((err) => {
									console.log(err);
								})
								.finally(() => setIsLoading(false));
						} else {
							setIsLoading(true);
							onBtnClick(
								{
									id: task?.id,
									is_completed: false,
								},
								() => setIsLoading(false)
							);
						}
					}}
					icon={btnIcon}
					loading={isLoading}
				>
					{!integration?.action_performed ? btnText : "Complete Task"}
				</CustomButton>
			)}
			{component && component}
		</div>
	);
};

export default PassTasks;
