import HomeIcon from "../assets/images/home.svg";
import BriefcaseIcon from "../assets/images/briefcase.svg";
import UserSearchIcon from "../assets/images/user-search.svg";
import PeopleIcon from "../assets/images/people-menu-icon.svg";
import ProjectIcon from "../assets/images/project-icon.svg";
import OnboardingIcon from "../assets/images/onboarding-menu-icon.svg";
import DollarIcon from "../assets/images/dollar-sign.svg";
import SettingIcon from "../assets/images/setting.svg";
import ClockIcon from "../assets/images/clock 1.svg";
import ReimbursementIcon from "../assets/images/corner-down-left.svg";
import FileIcon from "../assets/images/file-text.svg";
import CalendarIcon from "../assets/images/calendar.svg";
import AnalyticsIcon from "../assets/images/menu-analytics-icon.svg";
import AssetsIcon from "../assets/images/assets-icon.svg";
import AttendanceIcon from "../assets/images/menu-attendance-icon.svg";
import ExitIcon from "../assets/images/x-circle.svg";
import HealthIcon from "../assets/images/healthIcon.svg";
import ApprovalsIcon from "../assets/images/circle-tick.svg";
import FeedbackIcon from "../assets/images/feedback.svg";
import AIAssistantIcon from "../assets/images/ai-icon.svg" 
import FolderIcon from "../assets/images/folder-icon.svg";

export interface backendRouteObjectType
	extends Omit<routeObjectType, "subRoutes" | "route" | "name"> {
	name?: string;
	subRoutes?: backendRouteObjectType[];
	scope?: string[];
}

export interface IPolicies {
	[service: string]: IPolicy;
}

export interface IPolicy {
	service: string;
	name: string;
	scope: string[];
}

export interface routeObjectType {
	service: string;
	name: string;
	route: string;
	subRoutes?: allRoutesType;
	icon?: string;
	group?: string;
}

export interface allRoutesType {
	[service: string]: routeObjectType;
}

export const defaultRoutes: allRoutesType = {
	HOME: {
		service: "HOME",
		name: "Home",
		route: "/home",
		icon: HomeIcon,
	},
	TIME_OFF: {
		service: "TIME_OFF",
		name: "Time Off",
		route: "/time_off",
		icon: ClockIcon,
	},
	ATTENDANCE: {
		service: "ATTENDANCE",
		name: "Attendance",
		route: "/attendance",
		icon: AttendanceIcon,
	},
	CALENDAR: {
		service: "CALENDAR",
		name: "Calendar",
		route: "/events_calendar",
		icon: ClockIcon,
	},
	PAYSLIPS: {
		service: "PAYSLIPS",
		name: "Payslips",
		route: "/payslips",
		icon: FileIcon,
	},
	REIMBURSEMENTS: {
		service: "REIMBURSEMENTS",
		name: "Reimbursement",
		route: "/reimbursement",
		icon: ReimbursementIcon,
	},
	HEALTH: {
		service: "HEALTH",
		name: "Health",
		route: "/health",
		icon: HealthIcon,
	},
	AI_ASSISTANT: {
		service: "AI_ASSISTANT",
		name: "AI Assistant",
		route: "/ai-assistant",
		icon: AIAssistantIcon,
	},
	// CANDIDATES: {
	//   service: "CANDIDATES",
	//   name: "Candidates",
	//   route: "/candidates",
	//   icon: People2Icon,
	// },
	// EMPLOYEES: {
	//   service: "EMPLOYEES",
	//   name: "Employees",
	//   route: "/employees",
	//   icon: PeopleIcon,
	// },
	// BILLING: {
	//   service: "BILLING",
	//   name: "Billing",
	//   route: "/billing",
	//   icon: BriefcaseIcon,
	// },
	// REWARDS: {
	//   service: "REWARDS",
	//   name: "Rewards",
	//   route: "/rewards",
	//   icon: StarIcon,
	// },
	// REPORTS: {
	//   service: "REPORTS",
	//   name: "Reports",
	//   route: "/reports",
	//   icon: ChartIcon,
	// },
	TENANT: {
		service: "TENANT",
		name: "Tenants",
		route: "/tenants",
		icon: SettingIcon,
	},
	FEEDBACK: {
		service: "FEEDBACK",
		name: "Feedbacks",
		route: "/feedbacks",
		icon: FeedbackIcon,
	},
};

export const adminRoutes: allRoutesType = {
	PEOPLE: {
		service: "PEOPLE",
		name: "People",
		route: "/team/members",
		icon: PeopleIcon,
	},
	// EXITS: {
	// 	service: "EXITS",
	// 	name: "Exits",
	// 	route: "/exits",
	// 	icon: ExitIcon,
	// },
	SQUADS: {
		service: "SQUADS",
		name: "Squads",
		route: "/squads",
		icon: PeopleIcon,
	},

	JOB_REQUISITION: {
		service: "JOB_REQUISITION",
		name: "Job Requisition",
		route: "/job_requisition",
		icon: UserSearchIcon,
	},
	// INTERVIEW_PANEL: {
	//   service: "INTERVIEW_PANEL",
	//   name: "Interview Panel",
	//   route: "/interview_panel",
	//   icon: BriefcaseIcon,
	// },
	// ONBOARDING: {
	// 	service: "ONBOARDING",
	// 	name: "Onboarding",
	// 	route: "/onboarding",
	// 	icon: OnboardingIcon,
	// },
	// TEAM: {
	//   service: "TEAM",
	//   name: "Team",
	//   route: "/team",
	//   icon: PeopleIcon,
	// },
	PROJECTS: {
		service: "PROJECTS",
		name: "Projects",
		route: "/projects",
		icon: ProjectIcon,
	},
	PAYROLL: {
		service: "PAYROLL",
		name: "Payroll",
		route: "/payroll",
		icon: DollarIcon,
	},
	APPROVALS: {
		service: "APPROVALS",
		name: "Approvals",
		icon: ApprovalsIcon,
		route: "/approvals",
		subRoutes: {
			TIMEOFF: {
				service: "APPROVALS_TIME_OFF",
				name: "Time-off",
				route: "/approvals/timeoffs",
				icon: ApprovalsIcon,
			},
			REIMBURSEMENT: {
				service: "APPROVALS_REIMBURSEMENTS",
				name: "Reimbursements",
				route: "/approvals/reimbursements",
				icon: ApprovalsIcon,
			},
			ATTENDANCE: {
				service: "APPROVALS_ATTENDANCE",
				name: "Attendance",
				route: "/approvals/attendance",
				icon: ApprovalsIcon,
			},
		},
	},
	ASSETS: {
		service: "ASSETS",
		name: "Assets",
		route: "/assets",
		icon: AssetsIcon,
	},
	ANALYTICS: {
		service: "ANALYTICS",
		name: "Analytics",
		route: "/analytics",
		icon: AnalyticsIcon,
	},
RESOURCE_LIBRARY: {
		service: "RESOURCE_LIBRARY",
		name: "Resource Library",
		route: "/resource-library",
		icon: FolderIcon,
	},
	SETTINGS: {
		service: "SETTINGS",
		name: "Admin Settings",
		icon: SettingIcon,
		route: "/settings",
		subRoutes: {
			ORGANISATION: {
				service: "ORGANISATION",
				name: "Organisation Details",
				route: "/settings/organisation_details",
				icon: UserSearchIcon,
				group: "ORGANISATION",
			},
			// account_settings: {
			//   service: "account_settings",
			//   name: "Account Settings",
			//   route: "/settings/account-settings",
			//   group: "ORGANISATION",
			// },
			// billing: {
			//   service: "billing",
			//   name: "Billing",
			//   route: "/settings/billing",
			//   icon: BriefcaseIcon,
			//   group: "ORGANISATION",
			// },
			HOLIDAYS: {
				service: "HOLIDAYS",
				name: "Holiday Calendar",
				route: "/settings/holiday_calendar",
				icon: CalendarIcon,
				group: "ORGANISATION",
			},

			PAYROLL_SETTINGS: {
				service: "PAYROLL_SETTINGS",
				name: "Payroll Settings",
				route: "/settings/payroll_settings",
				icon: DollarIcon,
				group: "ORGANISATION",
				subRoutes: {
					PAYROLL_CYCLE: {
						service: "PAYROLL_CYCLE",
						name: "Payroll Cycle",
						route: "/settings/payroll_settings/payroll_cycle",
						icon: DollarIcon,
					},
					STATUTORY_COMPONENTS: {
						service: "STATUTORY_COMPONENTS",
						name: "Statutory Components",
						route: "/settings/payroll_settings/statutory_components",
						icon: DollarIcon,
					},
					SALARY_COMPONENTS: {
						service: "SALARY_COMPONENTS",
						name: "Salary Components",
						route: "/settings/payroll_settings/salary_components",
					},
					PAYSLIP_SETTINGS: {
						service: "PAYSLIP_SETTINGS",
						name: "Payslip Settings",
						route: "/settings/payroll_settings/payslip_settings",
					},
				},
			},
			EXIT_SETTINGS: {
				service: "EXIT_SETTINGS",
				name: "Exit Settings",
				route: "/settings/exit_settings",
				icon: ExitIcon,
				group: "ORGANISATION",
				subRoutes: {
					GENERAL_SETTINGS: {
						service: "GENERAL_SETTINGS",
						name: "General Settings",
						route: "/settings/exit_settings/general_settings",
						icon: ExitIcon,
					},
					INITIATION_SETTINGS: {
						service: "INITIATION_SETTINGS",
						name: "Initiation Settings",
						route: "/settings/exit_settings/initiation_settings",
						icon: ExitIcon,
					},
					CLEARANCE_PHASE_SETTINGS: {
						service: "CLEARANCE_PHASE_SETTINGS",
						name: "Clearance Phase Settings",
						route: "/settings/exit_settings/clearance_phase_settings",
						icon: ExitIcon,
					},
					CHECKLIST_CONFIGURATION: {
						service: "CHECKLIST_CONFIGURATION",
						name: "Checklist Configuration",
						route: "/settings/exit_settings/checklist_configuration",
						icon: ExitIcon,
					},
				},
			},
			POLICIES: {
				service: "POLICIES",
				name: "Policies",
				route: "/settings/policies",
				icon: UserSearchIcon,
				group: "ORGANISATION",
			},
			ROLES: {
				service: "ROLES",
				name: "Roles & Permissions",
				route: "/settings/roles-permissions",
				icon: BriefcaseIcon,
				group: "ORGANISATION",
			},
		},
	},
};

export const superAdminPolicies: IPolicies = {
	TENANT: {
		service: "TENANT",
		name: "Tenant",
		scope: ["VIEW"],
	},
	FEEDBACK: {
		service: "FEEDBACK",
		name: "Feedback",
		scope: ["VIEW"],
	},
};

export const gradientMenuItems = ["AI_ASSISTANT"];
