import axios from "axios";
import { useEffect } from "react";
import { Col, Form, Row } from "antd";
import { FormInstance } from "antd/lib";
import { Input } from "../../../../components/form-fields";
import Heading from "../../../../components/heading";
import { ifscRegex } from "../../../../utils/regexp";

export interface IBankBranchDetails {
	BRANCH: string;
	CENTRE: string;
	DISTRICT: string;
	STATE: string;
	ADDRESS: string;
	CONTACT: string;
	IMPS: boolean;
	CITY: string;
	UPI: boolean;
	MICR: string;
	RTGS: boolean;
	NEFT: boolean;
	SWIFT: string | null;
	ISO3166: string;
	BANK: string;
	BANKCODE: string;
	IFSC: string;
}

interface IProps {
	form: FormInstance<any>;
}

const BankingAndTax = ({ form }: IProps) => {
	const watchIFSC = Form.useWatch("bank_ifsc_code", form);

	useEffect(() => {
		if (!!watchIFSC) getBranchDetails(watchIFSC);
		else {
			form.setFieldsValue({
				bank_name: undefined,
        bank_branch: undefined,
			});
		}
	}, [watchIFSC]);

	const getBranchDetails = (IFSC: string) => {
		const apiUrl = ` https://ifsc.razorpay.com/${IFSC}`;

		axios
			.get(apiUrl)
			.then((response) => {
				form.setFieldsValue({
					bank_name: response.data.BANK,
					bank_ifsc_code: response.data.IFSC,
          bank_branch: response.data.BRANCH,
				});
			})
			.catch(()=>{
				form.setFieldsValue({
					bank_branch: undefined,
					bank_account_number: undefined,
				});
			})
	};

	return (
		<>
			<Heading title="Bank Details" />
			<Row gutter={20}>
				<Col sm={24} md={12}>
					<Form.Item
						label="IFSC Code"
						name="bank_ifsc_code"
						rules={[
							{
								required: true,
								message: "Please input IFSC code!",
								whitespace: true,
							},
							{
								pattern: ifscRegex,
								message: "Please input a valid IFSC code!",
							},
						]}
					>
						<Input placeholder="Enter bank IFSC code" />
					</Form.Item>
				</Col>

				<Col sm={24} md={12}>
					<Form.Item
						label="Bank name"
						name="bank_name"
						rules={[
							{
								required: true,
								message: "Please input bank name!",
								whitespace: true,
							},
						]}
					>
						<Input placeholder="Enter bank name" />
					</Form.Item>
				</Col>

				<Col sm={24} md={12}>
					<Form.Item
						label="Branch "
						name="bank_branch"
						rules={[
							{
								required: true,
								message: "Please input branch!",
								whitespace: true,
							},
						]}
					>
						<Input placeholder="Enter branch" />
					</Form.Item>
				</Col>

				<Col sm={24} md={12}>
					<Form.Item
						label="Account Number"
						name="bank_account_number"
						rules={[
							{
								required: true,
								message: "Please input bank account number!",
								whitespace: true,
							},
						]}
					>
						<Input placeholder="Enter bank account number " />
					</Form.Item>
				</Col>
			</Row>
		</>
	);
};

export default BankingAndTax;
